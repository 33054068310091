<template>
  <div>
    <div class="feedback-gut">
      <div class="container">
        <div class="row">
          <div class="col-6 center">
            <p class="psemibold fontcolor-white">{{date}}</p>
          </div>
          <div class="col-6 col-right center">
            <!-- Info aus Datenbank -->
            <h2 class="h2 display-inline fontcolor-white"><span v-for="(val, i) in value.data" :key="val + i" ><br v-if="i>0" />{{val}}</span></h2>
            <h4 class="h4 display-inline fontcolor-white" style="padding-left:8px">{{unit}}</h4>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: ['date', 'value', 'unit']
}
</script>