<template>
  <div class="bodywhite">
    <div class="header-light">
      <div class="container">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-mainblue">Werte Bericht</p>
          </div>
          <button class="col-2 col-right" @click="schliessenzuhome()">
              <img src="../assets/icons/close.svg">
          </button>
        </div>
      </div>
    </div>

    <div class="paddingtop108">
      <werteverlauf-listelement date="Temperatur" :value='getWerte("temperatur")' unit="°C"  minNormal="36.5" maxNormal="37.5" minBeobachtung="36" maxBeobachtung="38"></werteverlauf-listelement>
      <werteverlauf-listelement date="Gewicht" :value='getWerte("gewicht")' unit="kg" :minNormal="parseFloat(getGewicht) - 0.74" :maxNormal="parseFloat(getGewicht) + 0.74" :minBeobachtung="parseFloat(getGewicht) - 0.75" :maxBeobachtung="parseFloat(getGewicht) - 0.75"></werteverlauf-listelement>
      <werteverlauf-listelement date="Blutdruck" :value='getWerte("blutdruck")' unit="mmHg" minNormal="95" maxNormal="130" minBeobachtung="90" maxBeobachtung="165"></werteverlauf-listelement>
      <werteverlauf-listelement date="Befinden" :value="getBefinden()" unit=""></werteverlauf-listelement>
      <werteverlauf-listelement-multiple date="Symptome" :value="getSymptome()" unit=""></werteverlauf-listelement-multiple>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonfullwidth fontcolor-white backgroundcolor-mainblue" @click="werteabfragewerteberichtzuhome()">
          <p class="pbold display-inline fontcolor-white">Beenden</p>
          <img class="display-inline" src="../assets/icons/done.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WerteverlaufListelementVue from '../components/WerteverlaufListelement.vue';
import WerteverlaufListelementMultipleVue from '../components/WerteverlaufListelementMultiple.vue';
import router from '../router';

export default {
  components: {
    'werteverlauf-listelement' : WerteverlaufListelementVue,
    'werteverlauf-listelement-multiple' : WerteverlaufListelementMultipleVue,
  },
  mounted() {
    //this.focusInput();
  },
  methods: {
    /*focusInput() {
      this.$refs.input.$el.focus();
    },*/
    schliessenzuhome() {
      router.push({path: '/home'})
    },
    werteabfragewerteberichtzuhome() {
      router.push({path: '/home'})
    },
    getBefinden(){
      let befindenStrings = ["ausgezeichnet", "sehr gut", "gut", "weniger gut", "schlecht"];
      let index = parseInt(this.getWerte("befinden").data) - 1;
      console.log("index: " + index);
      console.log("befinden: " + befindenStrings[index]);
      let befinden = { data: befindenStrings[index], id: '24098u3890'};
      console.log(befinden);
      return befinden ;
    },
    getSymptome(){
      let result = [];
      let symptomName = ["Müdigkeit", "Urinausscheidung", "Schwellung der Beine", "Schwäche", "Schmerzen im Transplantatbereich", "Sonstige"];
      let symptome = ["symptommuedigkeit", "symptomverringerteurinausscheidung", "symptomschwellungderbeine", "symptomschwaeche", "symptomschmerzenimtransplantatbereich", "symptomsonstiges"];
      for(let i = 0; i<symptome.length; i++){
        let val = this.getWerte(symptome[i]).data;
        console.log(val + " " + symptomName[i]);
        if(val){
          result.push(symptomName[i]);
        }
      }
      console.log("symptome: " + result);
      return { data: result, id: '345459u09'};
    }
  },
  computed: {
    ...mapGetters(["getWerte",  "getGewicht"]),
  },
  
}
</script>


<style scoped>

.stepnavigation {
  background-color: #F6F6F6;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 95px;
  bottom: 0;
  padding-top: 15px;
  z-index: 2;
}

.input {
    color: #0B1010;
    text-align: right;
    font-size: 65px;
    font-weight: bold;
    width: 85%;
    border: none;
    height: 320px;
}

.input:focus {
outline: none;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
</style>